<template>
  <div class="tw-container"
    v-if="
      (socialMessages.length > 0 && currentPage === 'BatchHandleFbMessages') ||
      currentPage === 'Orders'
    "
  >
    <div class="row" v-if="fieldFilter !== null">
      <p class="col-6 tw-border-start fw-bolder mb-3">
        <span v-if="currentPage === 'Orders'">社群留言</span>
        <span v-else>{{ merchandiseInfo.name }}</span>
      </p>
      <p class="col-6 fw-bolder">
        <p>
          搜尋條件:
          <span
            v-if="recordAdvancedSearchData.singleSelect"
            class="badge bg-primary me-1 mb-1 text-break"
          >
            文章狀態: {{ recordAdvancedSearchData.singleSelect.postStatus }}
          </span>
        </p>
      </p>
    </div>
    <DataTable
      class="p-datatable-sm"
      :scrollHeight="scrollHeight"
      :value="socialMessages"
      dataKey="id"
      :loading="dataTabelLoading"
      :rowHover="true"
      :paginator="true"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows="20"
      :rowsPerPageOptions="[20, 50, 100]"
      :scrollable="true"
      currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
      v-model:filters="searchGlobal"
      filterDisplay="row"
      stateStorage="local"
      stateKey="dt-state-fbMessages-local"
      sortField="messageCreateTime"
      :sortOrder="-1"
      v-model:expandedRows="expandedRows"
      stripedRows
    >
      <template #header>
        <div class="d-flex align-items-center mb-3">
          <FieldFilter
            whitchOneTable="fb"
            :D4FieldFilter="fieldFilter"
          ></FieldFilter>
          <div class="w-100">
            <p>表格搜尋</p>
            <input
              class="form-control"
              v-model="searchGlobal['global'].value"
              placeholder="表格搜尋: 顧客名稱，留言時間，留言內容，社群類型內的類型，狀態"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <button class="tw-btn tw-btn-success me-2 mb-2" @click="expandAll">
              ＋展開全部</button
            ><button class="tw-btn tw-btn-success mb-2" @click="collapseAll">
              －收合全部
            </button>
          </div>
          <button
            class="tw-btn tw-btn-success"
            @click="showModal('advancedSearch')"
          >
            進階搜尋
          </button>
        </div>
      </template>
      <Column :expander="true" style="max-width: 50px" />
      <!-- 頭像 -->
      <Column v-if="fieldFilter[0].selected" field="" header="FB頭貼" style="min-width: 90px; max-width: 90px">
        <template #body="{ data }">
          <Image
            :url="data.profilePicture"
            v-if="data.profilePicture"
            alt="大頭貼"
            style="width: 50px; height: 50px"
          ></Image>
          <img
            src="@/assets/other-images/nobody.png"
            alt="沒頭貼"
            v-else
            style="width: 50px; height: 50px"
          />
        </template>
      </Column>
      <!-- 顧客名稱 -->
      <Column
        v-if="fieldFilter[1].selected"
        field="name"
        header="FB名稱"
        sortable
        style="min-width: 150px; max-width: 150px"
      >
        <template #body="{ data }">
          <div class="fw-bolder">
            <p
              class="text-break"
              v-if="!data.appUserId"
            >
              <span v-if="data.name">{{ data.name }} <span class="text-success">(未授權顧客)</span></span>
              <span class="text-danger" v-else>未授權顧客</span>
            </p>
            <p class="text-break" v-else-if="data.appUserId && !data.participant">
              {{ data.name }}
              <span class="text-danger">(非賣場顧客)</span>
            </p>
            <p class="text-break" v-else-if="data.participant">
              {{ data.name }}
              <span class="text-warning" v-if="data.participant.verify === null">(尚未審核)</span>
              <span class="text-danger" v-else-if="data.participant.verify === false">(審核不通過)</span>
            </p>
          </div>
        </template>
      </Column>
      <!-- 留言時間 -->
      <Column
        v-if="fieldFilter[2].selected"
        field="messageCreateTime"
        header="留言時間"
        sortable
        style="min-width: 150px;"
      >
      </Column>
      <!-- 留言內容 -->
      <Column
        v-if="fieldFilter[3].selected"
        field="messageContent"
        header="留言內容"
        sortable
        style="min-width: 150px"
      >
        <template #body="{ data }">
          <a class="text-primary pointer" @click="openNewWindow(data.url)">{{ data.messageContent }}</a>
        </template>
      </Column>
      <!-- 社群類型 -->
      <Column
        v-if="fieldFilter[4].selected"
        field="linkType"
        header="社群類型"
        sortable
        style="min-width: 150px; max-width: 150px"
      >
        <template #body="{ data }">
          <p>類型: {{ data.linkType }}</p>
          <p>名稱: {{ data.fbLinkName }}</p>
        </template>
      </Column>
      <!-- 狀態 -->
      <Column
        v-if="fieldFilter[5].selected"
        field="messageStatus"
        header="狀態"
        sortable
        style="min-width: 150px"
      >
        <template #body="{ data }">
          <p
            class="badge bg-danger pointer can-click"
            v-if="data.messageStatus === '未處理'"
            @click="updateHandle(data)"
          >
            {{ data.messageStatus }}
          </p>
          <p
            class="badge bg-success"
            v-else-if="data.messageStatus === '是訂單'"
          >
            {{ data.messageStatus }}
          </p>
          <p
            class="badge bg-warning"
            v-else-if="data.messageStatus === '非訂單'"
          >
            {{ data.messageStatus }}
          </p>
          <hr />
          <p class="tw-text-size18 fw-bolder text-danger">
            訂單總額:
            <span v-if="data.totalPrice !== null">{{ data.totalPrice }}元</span>
            <span v-else>計算中...</span>
          </p>
        </template>
      </Column>
      <!-- 操作 -->
      <Column v-if="fieldFilter[6].selected" field="" header="操作" style="min-width: 100px">
        <template #body="{ data }">
          <button
            class="tw-btn tw-btn-success"
            @click="showModal('addOrderBySeller', data)"
            v-if="data.participant && !data.participant.deleted && data.participant.verify"
          >
            加單
          </button>
          <button
            class="tw-btn tw-btn-danger"
            @click="showModal('addOrderBySeller', data)"
            v-else
          >
            代客加單
          </button>
        </template>
      </Column>
      <!-- 訂單 -->
      <template #expansion="slotProps">
        <p class="fw-bolder text-danger" v-if="slotProps.data.merchOrders === null">
          請稍後~正在為您取得訂單!
        </p>
        <template v-else>
          <div v-if="slotProps.data.userFbCommentLinkErrorRecords.length > 0">
            <p class="fw-bolder mb-1 text-danger">留言建立訂單失敗的原因:</p>
            <ul>
              <li v-for="data in slotProps.data.userFbCommentLinkErrorRecords" :key="data.id">
                留言: {{ data.message }}，{{ data.reason }}
              </li>
            </ul>
            <hr>
          </div>
          <ul v-if="slotProps.data.merchOrders.length > 0">
            <li
              class="border-bottom pb-2 row align-items-center"
              v-for="data in slotProps.data.merchOrders"
              :key="data.id"
            >
              <!-- 商品圖 -->
              <div class="col-1">
                <Image
                  :imageHash="data.imageHash"
                  size="s"
                  v-if="data.imageHash"
                  :alt="data.imageHash"
                  style="width: 40px; height: 40px"
                ></Image>
                <img
                  src="@/assets/other-images/noImg.png"
                  alt=""
                  v-else
                  style="width: 40px; height: 40px"
                />
              </div>
              <!-- 訂單資訊 -->
              <div class="col-3">
                <p>顧客姓名: {{ data.buyer.user.name }}</p>
                <p>訂單編號: {{ data.id }}</p>
                <p>訂單備註: {{ data.note }}</p>
              </div>
              <!-- 商品資訊 -->
              <div class="col-3">
                <p>商品: {{ data.merchandiseName }}</p>
                <p>款式: {{ data.style }}</p>
              </div>
              <!-- 訂單明細 -->
              <div class="col-3 pointer can-click" @click="showModal('editOrder', data)">
                {{ data.quantity }} X {{ data.price }} =
                {{ $methods.numberToFixed(data.quantity * data.price) }}
              </div>
              <!-- 操作 -->
              <div class="col-2">
                <button class="tw-btn tw-btn-danger" @click="showModal('delOrder', data)">刪除</button>
              </div>
            </li>
          </ul>
          <p class="fw-bolder text-danger mb-2" v-else>目前尚無任何關聯訂單</p>
        </template>
      </template>
      <template #empty>
        <p class="text-center fw-bolder text-primary">
          目前尚未有任何資料 0..0
        </p>
      </template>
    </DataTable>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <template v-for="data in singleSelectsArray" :key="data.id">
                <li
                  class="row border-bottom"
                  v-if="
                    currentPage !== 'BatchHandleFbMessages' ||
                    (currentPage === 'BatchHandleFbMessages' && data.id !== 'commentStatus')
                  "
                >
                  <div class="col-3 my-2">{{ data.key }}</div>
                  <div class="col-9">
                    <div
                      class="select-radio my-2"
                      v-for="select in data.value"
                      :key="select.id"
                    >
                      <label>
                        <input
                          type="radio"
                          :name="`${data.id}${merchandiseId}`"
                          :value="select.value"
                          v-model="advancedSearchData.singleSelect[data.id]"
                        />
                        <span class="radio-style">{{ select.value }}</span>
                      </label>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯訂單 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editOrderModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">訂單編輯</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="ms-1" v-if="selectOrderData.info">
            <p class="mb-2">
              <span class="fw-bolder">商品名稱：</span>
              {{ selectOrderData.info.merchandiseName }}
            </p>
            <br />
            <p class="mb-2">
              <span class="fw-bolder">商品樣式：</span>
              {{ selectOrderData.info.style }}
            </p>
            <br />
            <p class="mb-2">
              <span class="fw-bolder">顧客名稱：</span>
              {{ selectOrderData.info.buyer.user.name }}
            </p>
            <br />
          </div>
          <div>
            <div class="form-floating mb-3">
              <input
                onwheel="this.blur()"
                type="number"
                class="form-control"
                id="OrderQuantity"
                min="0"
                v-model="selectOrderData.quantity"
                pattern="[0-9]*"
                oninput="this.value=this.value.replace(/\D/g,'')"
              />
              <label for="OrderQuantity">數量</label>
            </div>
            <div class="form-floating mb-3">
              <input
                onwheel="this.blur()"
                type="number"
                class="form-control"
                id="OrderPrice"
                min="0"
                v-model="selectOrderData.price"
                @input="
                  selectOrderData.price = $methods.numberToFixed(
                    selectOrderData.price
                  )
                "
              />
              <label for="OrderPrice">價格</label>
            </div>
            <div class="form-floating mb-3">
              <input
                onwheel="this.blur()"
                type="number"
                class="form-control"
                id="OrderTotalPrice"
                :value="$methods.numberToFixed(
                    selectOrderData.price * selectOrderData.quantity
                  )"
                disabled
              />
              <label for="OrderTotalPrice">總價</label>
            </div>
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                id="OrderNote"
                placeholder="輸入訂單備註於此"
                maxlength="300"
                style="height: 100px"
                rows="5"
                v-model="selectOrderData.note"
              />
              <label for="OrderNote">備註(300字)</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateOrder"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除訂單 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delOrderModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bolder
            "
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="mb-2 fw-bolder" v-if="selectOrderData.info">
            是否將訂單 ID 為 {{ selectOrderData.info.id }} 刪除?
            <span class="fw-bolder text-danger">注意! 此步驟無法恢復</span>
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="delOrder">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <AddOrderBySellerForFbMessages
    :participants="participants"
    :propsSelectInfos="propsSelectInfos"
    :showAddOrderBySeller="showAddOrderBySeller"
    :D4ParticipantId="D4ParticipantId"
    :userFbCommentLinkId="userFbCommentLinkId"
    :messageInfo="messageInfo"
    @notifyParentFromAddOrderBySeller="notifyParentFromAddOrderBySeller"
  ></AddOrderBySellerForFbMessages>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// components
import FieldFilter from '@/components/tools/FieldFilter.vue'
import AddOrderBySellerForFbMessages from '@/components/modal/AddOrderBySellerForFbMessages.vue'

export default {
  components: { DataTable, Column, ColumnGroup, Row, FieldFilter, AddOrderBySellerForFbMessages },
  props: {
    merchandiseId: {
      type: Number,
      default: 0,
    },
    // 欄位偏好
    fieldFilter: {
      type: Object,
      default: null
    },
    participants: {
      type: Object,
      default: {
        loading: true,
        data: []
      }
    },
  },
  emits: ['notifyParentFromFbMessagesDataTable'],
  data() {
    return {
      // modal
      advancedSearchModal: {},
      editOrderModal: {},
      delOrderModal: {},
      // data
      serverToken: '',
      storeId: 0,
      currentPage: '',
      // dataTable
      scrollHeight: null,
      dataTabelLoading: false,
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      expandedRows: [],
      // 留言列表
      merchandiseInfo: {},
      socialMessages: [],
      // FB 代客加單
      propsSelectInfos: {
        show: 'name',
        data: []
      },
      showAddOrderBySeller: false,
      D4ParticipantId: 0,
      userFbCommentLinkId: 0,
      messageInfo: {},
      // 選到的訂單的資料
      selectOrderData: {
        info: null,
        quantity: 0,
        price: 0,
        note: null,
      },
      // 進階搜尋
      advancedSearchData: {
        singleSelect: {
          postStatus: '僅顯示同步最新的文章',
          commentStatus: '全部',
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  watch: {
    merchandiseId: {
      handler(val) {
        console.log(val)
        if (val > 0) {
          this.initialization()
          this.getMerchandiseInfo()
          this.advancedSearch()
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.createModals(['advancedSearchModal', 'editOrderModal', 'delOrderModal'])
  },
  unmounted() {
    clearTimeout(this.sortoutFbOrders)
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.currentPage = this.$route.name
      // 紀錄 dataTabel 高度
      this.scrollHeight = 'flex'
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-fbMessages-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'postStatus',
          key: '文章狀態',
          value: [
            {
              id: 'A1',
              value: '僅顯示同步最新的文章'
            },
            {
              id: 'A2',
              value: '全部'
            },
          ]
        },
        {
          id: 'commentStatus',
          key: '留言狀態',
          value: [
            {
              id: 'B1',
              value: '全部'
            },
            {
              id: 'B2',
              value: '未處理'
            },
            {
              id: 'B2',
              value: '是訂單'
            },
            {
              id: 'B3',
              value: '非訂單'
            },
          ]
        }
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    showModal(status, item) {
      if (status === 'addOrderBySeller') {
        if (this.participants.loading) return this.SweetAlert('other', '請稍後~正在為您準備資料')
        if (item.participant && !item.participant.deleted && item.participant.verify) this.D4ParticipantId = item.participant.id
        else this.D4ParticipantId = 0
        this.userFbCommentLinkId = item.id
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data.push(this.merchandiseInfo)
        this.messageInfo = item
        console.log(item)
        this.showAddOrderBySeller = true
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      } else if (status === 'editOrder') {
        // 編輯訂單
        console.log(item)
        this.selectOrderData = {
          info: item,
          quantity: item.quantity,
          price: item.price,
          note: item.note
        }
        this.editOrderModal.show()
      } else if (status === 'delOrder') {
        // 刪除訂單
        this.selectOrderData = {
          info: item,
          quantity: item.quantity,
          price: item.price,
          note: item.note
        }
        this.delOrderModal.show()
      }
    },
    // 展開全部
    expandAll() {
      this.expandedRows = this.socialMessages.filter(item => item.id);
    },
    // 收合全部
    collapseAll() {
      this.expandedRows = []
    },
    getMerchandiseInfo() {
      const vm = this
      const getMerchandiseInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getMerchandiseStyles{getMerchandiseDiscounts}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            vm.merchandiseInfo = merchandiseInfo
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.dataTabelLoading = false
        },
      })
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      if (closeModal) this.advancedSearchModal.hide()
      this.getSocialMessages()
    },
    // * 更新資料 (start)
    /**
     * 更新資料
     * @updateItem 要更新的物件
     * @modal 需要關閉的 modal
     */
    updateData(updateItem, modal, otherData) {
      this.$methods.switchLoading('show')
      let data = []
      let ids = []
      switch (updateItem) {
        case 'userFbCommentLinks':
          const messageInfo = otherData
          data = [{
            ids: [messageInfo.id],
            type: 38,
            methods: '{getMerchOrders{getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles,getBuyer{getUser}}}'
          }]
          break;
        default:
          throw new Error('FbMessageDataTable.vue > updateData > updateItem 未處理')
      }
      const updateData = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      };
      const vm = this
      $.ajax({
        type: "POST",
        async: true,
        url: updateData,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            const userFbCommentLinks = res.data[0].objects
            vm.sortoutUpdateData(userFbCommentLinks, updateItem, modal)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 整理更新資料
    sortoutUpdateData(updateFinishedData, updateItem, modal) {
      this.socialMessages.forEach(origin => {
        switch (updateItem) {
          case 'userFbCommentLinks':
            updateFinishedData.forEach(update => {
              if (origin.id === update.id) {
                origin.handled = update.handled
                origin.messageStatus = this.handleMessageStatus(update.merchOrders, update.handled)
                origin.recordMerchOrders = JSON.parse(JSON.stringify(update.merchOrders))
                origin.totalPrice = this.handleTotalPrice(origin.recordMerchOrders)
                origin.merchOrders = []
                origin.merchOrders = this.handleMerchOrdersInfo(origin.recordMerchOrders, origin)
              }
            })
            break;
          case 'editOrder':
            if (origin.id === updateFinishedData.info.userFbCommentLinkId) {
              origin.merchOrders.some(order => {
                if (order.id === updateFinishedData.info.id) {
                  order.quantity = updateFinishedData.quantity
                  order.price = updateFinishedData.price
                  order.note = updateFinishedData.note
                  origin.totalPrice = this.handleTotalPrice(origin.merchOrders)
                }
              })
            }
            break;
            case 'delOrder':
              if (origin.id === updateFinishedData.info.userFbCommentLinkId) {
                origin.merchOrders = origin.merchOrders.filter(order => {
                  return order.id !== updateFinishedData.info.id
                })
                origin.totalPrice = this.handleTotalPrice(origin.merchOrders)
                origin.messageStatus = this.handleMessageStatus(origin.merchOrders, origin.handled)
              }
              break;
          default:
            throw new Error('FbMessageDataTable.vue > sortoutUpdateData > updateItem 未處理')
        }
      })
      this.SweetAlert('200')
      if (modal) this[modal].hide()
      this.$methods.switchLoading('hide')
    },
    // * 更新資料 (end)
    // * 取資料 (start)
    // 取得留言列表
    getSocialMessages() {
      this.dataTabelLoading = true
      const vm = this
      const getStocksApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getMerchFbFeedLinks{getStoreFbCommunityLink,getUserFbCommentLinks{getUserFbCommentLinkErrorRecords,getMerchOrders{getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles,getBuyer{getUser}},getParticipant{getUser{getUserFacebookLink}}}}}'
        }
      ]
      this.socialMessages = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getStocksApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            vm.sortOutSocialMessages(merchandiseInfo.merchFbFeedLinks)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.dataTabelLoading = false
        },
      })
    },
    // 整理留言與訂單
    sortOutSocialMessages(merchFbFeedLinks) {
      // 進階搜尋 > 文章狀態
      merchFbFeedLinks.forEach(item => {
        item.userFbCommentLinks.forEach(message => {
          let messageObject = {
            linkType: item.linkType === 'STOREFBGROUPLINK' ? '社團' : '粉專',
            storeFbCommunityLink: item.storeFbCommunityLink,
            fbLinkName: item.storeFbCommunityLink.name,
            messageCreateTime: this.$methods.moment(message.createTime).format('YYYY-MM-DD HH:mm:ss'),
            commentId: message.commentId,
            appUserId: message.appUserId,
            name: message.name,
            handled: message.handled,
            id: message.id,
            messageContent: message.message,
            participant: message.participant,
            profilePicture: null,
            merchOrders: null,
            recordMerchOrders: JSON.parse(JSON.stringify(message.merchOrders)),
            messageStatus: '未處理',
            totalPrice: null,
            url: message.url,
            userFbCommentLinkErrorRecords: message.userFbCommentLinkErrorRecords
          }

          // * 需特別處理
          // 處理大頭貼
          if (message.participant && message.participant.user && message.participant.user.userFacebookLink && message.participant.user.userFacebookLink.profilePicture) messageObject.profilePicture = message.participant.user.userFacebookLink.profilePicture
          else if (message.profilePicture) messageObject.profilePicture = message.profilePicture

          // 留言狀態
          messageObject.messageStatus = this.handleMessageStatus(message.merchOrders, message.handled)

          let matchCurrentPage = false
          switch (this.currentPage) {
            // 如果是在"批次處理FB錯誤留言頁面，就要多過濾 messageStatus = 未處理"
            case 'BatchHandleFbMessages':
              if (messageObject.messageStatus === '未處理') matchCurrentPage = true
              break;
            default:
              matchCurrentPage = true
              break;
          }

          // 進階搜尋
          let matchPostStatus = false
          switch (this.advancedSearchData.singleSelect.postStatus) {
            case '僅顯示同步最新的文章':
              if (item.latest) matchPostStatus = true
              break;
            default:
              // 顯示全部
              matchPostStatus = true
              break;
          }
          
          let matchCommentStatus = false
          if (this.currentPage !== "BatchHandleFbMessages") {
            switch (this.advancedSearchData.singleSelect.commentStatus) {
              case '全部':
                matchCommentStatus = true
                break;
              case '未處理':
                if (messageObject.messageStatus === '未處理') matchCommentStatus = true
                break;
              case '是訂單':
                if (messageObject.messageStatus === '是訂單') matchCommentStatus = true
                break;
              case '非訂單':
                if (messageObject.messageStatus === '非訂單') matchCommentStatus = true
                break;
            }
          } else matchCommentStatus = true
          
          if (matchPostStatus && matchCommentStatus && matchCurrentPage) this.socialMessages.push(messageObject)
        })
      })
      
      this.dataTabelLoading = false
      // 非同步計算訂單總價
      this.asyncHandle()
    },
    // 特別處理留言狀態
    handleMessageStatus(merchOrders, handled) {
      let result = '未處理'
      if (merchOrders.length > 0) {
        result = '是訂單'
      } else if (handled && merchOrders.length === 0) {
        result = '非訂單'
      } else if (!handled && merchOrders.length === 0) {
        result = '未處理'
      }
      return result
    },
    // 非同步處理資料 (unmounted 結束執行)
    asyncHandle() {
      this.sortoutFbOrders = setTimeout(() => {
        this.socialMessages.forEach(item => {
          console.log(item)
          // 計算訂單總額
          item.totalPrice = this.handleTotalPrice(item.recordMerchOrders)

          // 處理訂單資訊
          item.merchOrders = []
          item.merchOrders = this.handleMerchOrdersInfo(item.recordMerchOrders, item)
        })
      }, 1)
    },
    // 特別處理訂單總額
    handleTotalPrice(merchOrders) {
      let totalPrice = 0
      merchOrders.forEach(order => {
        totalPrice += order.quantity * order.price
      })
      totalPrice = this.$methods.numberToFixed(totalPrice)
      return totalPrice
    },
    // 特別處理訂單資訊
    handleMerchOrdersInfo(merchOrders, userFbCommentLink) {
      let merchOrdersResult = []
      merchOrders.forEach(order => {
        // 紀錄在哪筆留言下面
        order.userFbCommentLinkId = userFbCommentLink.id
        // 組款式
        order.style = ''
        order.merchandiseStyles.forEach(style => {
          order.style += `${style.name} `
        })
        // 取商品名
        order.merchandiseName = order.merchandise.name
        // 取首圖
        order.imageHash = null
        order.merchandise.merchandisePictureLinks.some(img => {
          if (img.front) order.imageHash = img.imageHash
        })
        merchOrdersResult.push(order)
      })
      return merchOrdersResult
    },
    // * 取資料 (end)
    // 更新狀態
    updateHandle(message) {
      this.$methods.switchLoading('show')
      const vm = this
      const updateHandleApi = `${process.env.VUE_APP_API}/apps/fb/setHandled`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('userFbCommentLinkIds', message.id)
      data.append('handled', !message.handled)
      data.append('auto', false)
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateHandleApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            message.handled = true
            message.messageStatus = '非訂單'
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 編輯訂單
    updateOrder() {
      const vm = this
      this.$methods.switchLoading('show')
      const updateOrderApi = `${process.env.VUE_APP_API}/merchOrder/update`;
      const token = this.serverToken;
      const header = {
        authorization: token,
      }
      const obj = {
        id: this.selectOrderData.info.id,
        price: this.selectOrderData.price ? this.selectOrderData.price : 0,
        quantity: this.selectOrderData.quantity ? this.selectOrderData.quantity : 0,
        note: this.selectOrderData.note ? this.selectOrderData.note : null,
      }
      let data = []
      data.push(obj)
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateOrderApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.sortoutUpdateData(vm.selectOrderData, 'editOrder', 'editOrderModal')
            // 通知最外層的訂單表格元件重新整理資料
            vm.$emit('notifyParentFromFbMessagesDataTable', {
              reloadMerchOrderDataTable: true
            })
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除訂單
    delOrder() {
      const vm = this;
      const delMerchOrderApi = `${process.env.VUE_APP_API}/merchOrder/delete`;
      const token = this.serverToken;
      const header = {
        authorization: token,
      };
      let delArr = []
      delArr.push(this.selectOrderData.info.id)
      this.$methods.switchLoading('show')
      const merchOrderIds = delArr.toString()
      const data = new FormData()
      data.append("merchOrderIds", merchOrderIds)
      console.log("傳遞 API 刪除資料：",merchOrderIds)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delMerchOrderApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            // 刪除表格資料
            vm.sortoutUpdateData(vm.selectOrderData, 'delOrder', 'delOrderModal')
          } else {
            // 組部份成功文字
            let string = ''
            res.errorData.forEach(error => {
              string += '訂單ID: '
              error.errorData.forEach((item, index, arr) => {
                string += item.id
                if (index < arr.length - 1) string += ','
              })
              string += `，${error.message}\n`
            })
            vm.SweetAlert(res.code, string)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 開新分頁
    openNewWindow (url) {
      window.open(url, "_blank");
    },
    // 來自<代客加單>子層的通知
    notifyParentFromAddOrderBySeller(object) {
      console.log(object)
      this.showAddOrderBySeller = object.showAddOrderBySeller
      if (object.reloadMessage) {
        this.updateData('userFbCommentLinks', null, object.reloadMessage)
        // 通知最外層的訂單表格元件重新整理資料
        this.$emit('notifyParentFromFbMessagesDataTable', {
          reloadMerchOrderDataTable: true
        })
      }
      if (object.reloadPrticipants) {
        // 通知最外層的頁面 > 重新取得 participants
        this.$emit('notifyParentFromFbMessagesDataTable', {
          reloadPrticipants: true
        })
      }
    },
  },
}
</script>